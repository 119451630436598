<template>
  <div class="">
    <div class="container hero-section"></div>
    <div class="col-sm-2 col-xs-12 text-center  hero-txt-sec">
      <h1 class="hero-txt">About Us</h1>
    </div>
    <div class="body-txt">
      <p>
        In 1991, Ferenz Georgy started German Butcher in Bangladesh with a love
        for sausages noticing the unavailability of such products in our
        country. Since then, German Butcher is the pioneer of authentic German
        Sausages in Bangladesh and became the icon of premium quality gourmet
        sausages, cold cuts, ham, bacon, meatloaf, salami, pepperoni and so many
        meat based products.<br />
      </p>
      <p>
      When you bite into our sausage you can taste the unique blend of savory
      spices and seasonings we have worked so many years to be perfect. We
      learned a long time ago that real, authentic flavor can only come from
      real, authentic ingredients. That’s why our sausages are meticulously
      crafted with the finest ingredients and freshest meats and savoured with
      satisfying enthusiasm. Enjoy the taste of Germany right here in
      Bangladesh!<br />
    </p>
      <p>
        At German Butcher, we know that quality is always the most important
        ingredient. So, we never compromise on quality and using only the finest
        ingredients and freshest meats in all our specialties, sourced only from
        selected suppliers produced in accordance with stringent hygiene
        regulations and under expert supervision. Our objective is to ensure
        that all our products are made from the freshest meats, purest
        seasonings and spices, and perfectly cooked, cured, smoked or simply
        plain processed as the case may be. We always committed to provide our
        consumer affordable mouthwatering products that distinctly meets high
        standards of quality, freshness and taste. When we make our sausages, we
        make it keeping you in mind. We want you to relish every bite, and we
        want you to want more!<br />
      </p>
      <p>
        With more than 29 year experience in sausage industry, we have cemented
        our reputation as being the best quality sausage manufacturers in
        Bangladesh. We pride ourselves in giving you unique, exceptional
        experiences that match your food interests, palates, needs and prices.
        We don’t believe in making exaggerated claims, we believe in results.
        Try any of our products and we can promise you that it will be a class
        apart from others. Eat tasty, eat healthy, eat right, with us!
      </p>
    </div>
<div class="section-1 container">
  <div class="row">
    <div class="col-lg-6 col-sm-12 col-xs-12 text-right mt-5 card-1" style="margin-left:0px">
    
      <img
            class="img-fluid img-thumbnail logo-img"
            src="/img/new-image/aboutpic.png"
            alt=""
            style="margin-right:100px"
          />
          <p class="text-center mr-3 ">
            Where sausages are meticulously crafted with the finest ingredients
            and and savoured with satisfying enthusiasm
          </p>
    </div>
    <div class="col-lg-6 col-sm-12 sec-1 ">
      <img
            class="img-fluid img-thumbnail img-border ml-4"
            src="/img/new-image/Chairmen.png"
            alt=""
           
          />
          <p class="img-txt">
            Ferenz Georgy
          </p>

</div>
  </div>
  
  <!-- <v-container class="grey lighten-5   d-flex">
    <div
      v-for="j in justify"
      :key="j"
      :justify="j"
      class="d-sm-flex d-xs-block"
    >
      <v-col
        v-for="k in 1"
        :key="k"
        md="4"
        order-md="1"
        class="card-1 "
      >
        <img
            class="img-fluid img-thumbnail logo-img text-center"
            src="/img/new-image/aboutpic.png"
            alt=""
          />
          <p class="">
            Where sausages are meticulously crafted with the finest ingredients
            and and savoured with satisfying enthusiasm
          </p>
      </v-col>
      <div class="sec-1 d-none d-sm-block order-sm-2"> </div>
      <v-col
        v-for="k in 1"
        :key="k"
        md="4"
        order-md="3"
        class="text-center "
        
      >
      <img
            class="img-fluid img-thumbnail img-border"
            src="/img/new-image/Chairmen.png"
            alt=""
          />
          <p class="img-txt">
            Ferenz Georgy
          </p>
       
      </v-col>
    </div>
  </v-container> -->
</div>

  </div>
</template>

<script>

export default {
  name: "NewAboutus",
 
  data: () => ({
      justify: [
        
        'space-between',
        
      ],
    }),
};
</script>

<style scoped>
@font-face {
    font-family: 'Quicksand';
    src: url('../../../public/fonts/Quicksand-VariableFont_wght.ttf');
} 
@font-face {
    font-family: 'CastorTwoW01-Regular';
    src: url('../../../public/fonts/Castor\ Two\ W01\ Regular.ttf');
} 

.hero-section {
  min-width: 100%;
  background: no-repeat center center url("/img/new-image/about.png");
  background-size: cover;
  height: 512px;
}
.hero-txt-sec {
  margin-left: 9rem;
  margin-top: -2rem;
  background: #fff;
  border-radius: 1rem;
  padding: 10px 15px;
}
.hero-txt {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 50px;

  /* Black */
  color: #231f20;
}
.body-txt{
    margin: 2rem 12rem ;
}

.body-txt p {
font-family: 'Quicksand';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 22px;
letter-spacing: 0.02em;
text-align: justify;

/* Black */
color: #231F20;
}
.card-1{
    margin-top: 2rem;
    margin-left: 15rem;
    text-align: center;
    display: block;
}
.section-1{
margin-top: 5rem;
margin-bottom: 7rem;
}
.card-1 p{
margin-top: 2rem;
margin-left: 250px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
/* or 120% */
text-align: center;

/* Black */
color: #231F20;
}
.sec-1{
    border-left: 1.25px solid #C0272D;
    padding: 10px;
}
.img-txt{
margin-left: 90px;
font-family: 'Quicksand';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 133% */


/* Black */
color: #231F20;
}
@media (min-width: 770px )and (max-width: 965px) {
  .body-txt{
    margin: 2rem 6rem ;
}
  .hero-txt-sec {
    margin-left: 2rem;
  }
  .hero-txt{
  font-size: 30px !important
  ;
  line-height: 30px;
  text-align: justify !important;
}
.section-1{
margin-top: 5rem;
margin-bottom: 2rem;
}
.card-1{
   margin-top: 2rem;
    margin-left:5rem;
    text-align: center;
    display: block;
}

}
@media only screen and (max-width: 769px) {
    .first {
        order: 1;
    }
    .second {
        order: 2;
    }

.hero-section{
  height: 300px;
}
.hero-txt-sec {
  margin-left: 0rem;
  margin-top: 10px;
  background: #fff;
}
.hero-txt{
  font-size: 30px;
  line-height: 30px;
}
.body-txt{
    margin: 1rem ;

}
.body-txt p{
  font-size: 16px;
  text-align: justify;
}
.section-1{
margin-top: 1rem;
margin-bottom: 1rem;
}
.card-1{
  margin: 0;
}
.card-1 p{
  font-size: 16px;

  margin-left: 30px !important;
}
.sec-1{
  border: none;
  text-align: center;
}
.img-txt{
  margin-left: 0px !important;
  text-align: center;
}
  
}


</style>